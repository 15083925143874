@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dark-mode .App-header {
  background: linear-gradient(135deg, #0d0929 0%, #282c34 100%);
  color: #ffffff;
}

.light-mode .App-header {
  background: linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%);
  color: #000000;
}

.intro-text {
  font-size: 30px;
  font-weight: 300;
}

.typing-text {
  font-size: 30px;
  font-weight: 500;
}

.cursor {
  font-size: 30px;
}

.blink {
  animation: blink 1s linear infinite alternate;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* ------------------------------------------
   Card Styles
------------------------------------------- */
.card-container {
  position: relative;
  width: 300px;  /* default desktop width */
  height: 400px; /* default desktop height */
  margin: 0 auto;
  margin-bottom: 30px;
}

/* 2) The card itself fills the container's height/width */
.card {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s;
}

.card:hover {
  transform: scale(1.05);
}

/* ------------------------------------------
   Notification Styles
------------------------------------------- */
.notification-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.notification-banner {
  background-color: #ffefc2; /* Light yellow, change as desired */
  color: #333;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 1s;
  max-width: 80%;
  margin: 0 auto;
}

.fadeOut {
  opacity: 0;
  transition: opacity 1s;
}

.fadeIn {
  opacity: 1;
  transition: opacity 1s 1s;
}

/* ------------------------------------------
   Theme Toggle
------------------------------------------- */
.theme-toggle {
  position: fixed;
  top: 20px;
  right: 20px;
  background: none;
  border: 2px solid currentColor;
  border-radius: 20px;
  padding: 10px 15px;
  color: currentColor;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}

.theme-toggle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* ------------------------------------------
   Fancy Button (Projects Button)
------------------------------------------- */
.fancy-button {
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(45deg, #ff6b6b, #f94d6a);
  border: none;
  border-radius: 50px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.fancy-button:hover {
  background: linear-gradient(45deg, #f94d6a, #ff6b6b);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

.fancy-button:active {
  transform: translateY(1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

/* ------------------------------------------
   Modal
------------------------------------------- */
.dark-mode .modal-content {
  background-color: #0d0929;
  color: #ffffff;
}

.light-mode .modal-content {
  background-color: #ffffff;
  color: #000000;
}

.modal-header,
.modal-body {
  border-color: #444;
}

.btn-close {
  color: currentColor;
}

.btn-close:hover {
  color: #ccc;
}

/* ------------------------------------------
   Custom Modal
------------------------------------------- */
.custom-modal .modal-content {
  background-color: #f5f5f5;
  color: #333;
  border-radius: 16px;
}

.custom-modal .modal-header,
.custom-modal .modal-footer {
  border: none;
}

.custom-modal .modal-title {
  font-size: 28px;
  font-weight: 700;
}

.custom-modal .modal-header .btn-close {
  filter: invert(0);
}

.custom-modal .modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-link {
  color: #333;
  transition: color 0.3s;
}

.social-link:hover {
  color: #667eea;
}

.social-link.linkedin {
  color: #0077b5;
}

.social-link.email {
  color: #ea4335;
}

.social-link.github {
  color: #333;
}

.custom-modal .btn-secondary {
  background-color: #667eea;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-weight: 500;
}

.custom-modal .btn-secondary:hover {
  background-color: #5a67d8;
}

/* ------------------------------------------
   Extra Responsive Tweaks
------------------------------------------- */
@media (max-width: 768px) {
  .App-header {
    padding: 20px;
  }

  /* Make the text a bit smaller */
  .typing-text,
  .intro-text {
    font-size: 24px;
  }

  .cursor {
    font-size: 24px;
  }

  /* Adjust the card size on mobile */
  .card {
    height: 300px;
  }

  /* Resize the fancy-button for mobile */
  .fancy-button {
    font-size: 16px;
    padding: 12px 24px;
  }

  /* Position the theme-toggle closer to the edge */
  .theme-toggle {
    top: 10px;
    right: 10px;
    padding: 8px 12px;
  }

  /* Make sure notification fits well on mobile */
  .notification-banner {
    max-width: 90%;
    font-size: 14px;
  }
}

/* ------------------------------------------
   Additional Smaller Screen Tweaks
------------------------------------------- */
@media (max-width: 480px) {
  .typing-text,
  .intro-text {
    font-size: 20px;
  }

  .cursor {
    font-size: 20px;
  }

  .notification-banner {
    font-size: 13px;
    padding: 8px 16px;
  }
}

/* ------------------------------------------
   Extra Button Example
------------------------------------------- */
.distance-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background: linear-gradient(45deg, #4CAF50, #34A853); /* Green gradient */
  border: none;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.distance-button:hover {
  background: linear-gradient(45deg, #34A853, #4CAF50);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

.distance-button:active {
  transform: translateY(1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}


/* 3) Make them smaller on mobile (e.g., under 768px) */
@media (max-width: 768px) {
  .card-container {
    width: 220px;   /* narrower container on mobile */
    height: 300px;  /* shorter container on mobile */
  }
}

/* 4) The subtle "Try dragging" bubble */
.hint-bubble {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  background: #fffaf0;
  color: #333;
  padding: 8px 14px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  opacity: 0;
  animation: fadeInHint 0.8s ease forwards;
  max-width: 280px;
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
}

@keyframes fadeInHint {
  0% {
    transform: translate(-50%, 10px);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}
