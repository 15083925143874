@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');

.project-gallery {
  padding: 40px 20px;
  background-color: #f5f5f5;
  font-family: 'Poppins', sans-serif;
  color: #333;
}

.gallery-header {
  text-align: center;
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: 700;
}

.project-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
}

.project-card {
  background-color: #fff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.project-icon {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.project-content {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.project-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #333;
}

.project-description {
  font-size: 16px;
  font-weight: 300;
  flex-grow: 1;
  color: #666;
  margin-bottom: 20px;
}

.view-button {
  align-self: flex-start;
  padding: 10px 20px;
  background-color: #667eea;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-button:hover {
  background-color: #5a67d8;
}

@media (max-width: 768px) {
  .gallery-header {
    font-size: 28px;
  }

  .project-title {
    font-size: 20px;
  }

  .project-description {
    font-size: 14px;
  }
}
