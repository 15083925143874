.chatbot-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chat-window {
  width: 90vw;
  height: 80vh;
  max-width: 500px;
  max-height: 600px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-header {
  background-color: #667eea;
  color: #fff;
  padding: 15px;
  position: relative;
}

.chat-header h4 {
  margin: 0;
  font-size: 18px;
}

.close-chat {
  position: absolute;
  top: 5px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f9f9f9;
}

.message {
  display: flex;
  margin-bottom: 10px;
  font-size: 14px;
}

.message.user {
  justify-content: flex-end;
}

.message.bot {
  justify-content: flex-start;
}

.message .message-text {
  max-width: 70%;
  padding: 10px 15px;
  border-radius: 20px;
  line-height: 1.4;
  text-align: left;
}

.message.user .message-text {
  background-color: #667eea;
  color: #fff;
  border-bottom-right-radius: 0;
}

.message.bot .message-text {
  background-color: #e5e5ea;
  color: #000;
  border-bottom-left-radius: 0;
}

.chat-input {
  display: flex;
  border-top: 1px solid #ccc;
}

.chat-input input {
  flex: 1;
  padding: 12px;
  border: none;
  outline: none;
  font-size: 14px;
}

.chat-input button {
  background-color: #667eea;
  color: #fff;
  border: none;
  padding: 0 20px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #5a67d8;
}

.open-chat {
  background-color: #667eea;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 16px;
}

.open-chat:hover {
  background-color: #5a67d8;
}
